@import 'common/styles/customized-tailwind';

//
// Web-only Customizations
//

body {
  background-color: rgb(164, 182, 182);
}

@import './algolia-search';

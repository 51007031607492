/* https://codesandbox.io/embed/github/algolia/doc-code-samples/tree/master/React+InstantSearch/getting-started */

.ais-SearchBox-form {
  max-width: 660px;
  @apply relative mx-auto;
}

.ais-SearchBox-submit {
  @apply absolute left-3 top-4 pt-1;
  svg {
    @apply scale-150;
    fill: #bbb;
  }
}
.ais-SearchBox-reset {
  @apply hidden;
}

.ais-SearchBox-input {
  @apply p-3 w-full rounded pl-8;
}

.ais-Pagination {
  margin-top: 1em;
  @apply flex justify-center text-gray-500;
  ul {
    @apply flex space-x-2 text-lg;
  }
}

.ais-Hits-list {
  @apply grid grid-cols-1 md:grid-cols-2 gap-4;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  // https://github.com/Cosbgn/tailwindcss-tooltips
  .tooltip {
    @apply invisible absolute;
    @apply -mt-12;
    @apply py-1 px-2 rounded border border-gray-800 bg-black bg-opacity-70 text-gray-100 shadow-lg ml-4 text-sm;
  }

  .has-tooltip:hover .tooltip {
    @apply visible z-50;
  }
}
